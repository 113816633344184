<template>
    <div id="resident-store-basket" class="data-list-container">
        <div class="vx-row page-row">
            <div class="vx-col left-col">

                <BackButton
                  label="Retour au catalogue"
                  @click="onBackBtnClick"
                />
                <ResidentHeader
                  page-title="Panier"
                  :displayResidentMenu="false"
                  class="mt-sm-0"
                />

                <div class="delivery-header">
                    <img class="delivery-icon" src="@/assets/images/ehpad/icon-shopping-cart-black.svg" />
                    <div class="delivery-content">
                        <div class="delivery-title">
                            Mon panier
                        </div>
                        <div class="delivery-legend" v-if="isDraftHasProduct('subscription') && !isDraftHasProduct('once') && getResidentPaymentData(resident_id) != null">
                            Vérifiez vos nouveaux abonnements puis cliquez sur valider.<br/>
                            Vous pourrez à tout moment les modifier/annuler dans votre récapitulatif.
                        </div>
                        <div class="delivery-legend" v-else-if="isDraftHasProduct('subscription') && !isDraftHasProduct('once')">
                            Vérifiez votre panier puis passez à l'étape suivante pour enregistrer votre moyen de paiement.
                        </div>
                        <div class="delivery-legend" v-else>
                            Vérifiez votre panier puis passez à l'étape suivante pour choisir vos modes de livraison.
                        </div>
                    </div>
                </div>

                <div class="basket-empty" v-if="isDraftEmpty()">
                    <div class="title">Panier vide</div>
                    <div class="legend">Aucun produit n’a été ajouté au panier</div>
                </div>

                <div class="drafts" v-else>

                    <div class="basket-detail" v-if="isDraftHasProduct('once')">
                        <div class="detail-header">
                            <div class="header-title">Commandé une seule fois</div>
                            <div class="header-price">{{ amounts.once }}€</div>
                        </div>
                        <div class="detail-content" v-if="isDraftHasProduct('once','product')">
                            <div class="order-category">Produits</div>
                            <div class="order-items">
                                <div
                                    class="order-item"
                                    v-for="(product, productIndex) in getProducts('once', 'product')"
                                    :key="productIndex"
                                >
                                    <div class="item-photo">
                                        <img class="item-image" :src="product.photo" />
                                    </div>
                                    <div class="item-informations">
                                        <div class="item-title">{{ product.title }}</div>
                                        <div class="item-description" v-html="product.description"></div>
                                      <div class="item-description options" v-html="getProductOptions(product)"></div>
                                        <div class="item-legend">{{ product.legend }}</div>
                                    </div>
                                    <div class="item-actions">
                                        <QuantitySelector
                                            class="quantity-select"
                                            :value="product.quantity"
                                            @input="(quantity) => {onQuantityChange('once', quantity, product)}"
                                        />
                                        <div class="item-price">{{ product.price }}€</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="detail-content" v-if="isDraftHasProduct('once','service')">
                            <div class="order-category">Services</div>
                            <div class="order-items">
                                <div
                                    class="order-item"
                                    v-for="(service, serviceIndex) in getProducts('once', 'service')"
                                    :key="serviceIndex"
                                >
                                    <div class="item-photo">
                                        <img class="item-image" :src="service.photo" />
                                    </div>
                                    <div class="item-informations">
                                        <div class="item-title">{{ service.title }}</div>
                                        <div class="item-description" v-html="getServiceOptions(service)"></div>
                                        <div class="item-legend" v-html="service.legend"></div>
                                    </div>
                                    <div class="item-actions">
                                        <QuantitySelector
                                            class="quantity-select"
                                            :value="service.quantity"
                                            :addDisabled="true"
                                            @input="(quantity) => {onQuantityChange('once', quantity, service)}"
                                        />
                                        <div class="item-price">{{ service.price }}€</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="basket-detail" v-if="isDraftHasProduct('subscription')">
                        <div class="detail-header">
                            <div class="header-title">Ajouts d'abonnements</div>
                            <div class="header-price">
                                <!-- <p>{{ amounts.subscription }}€</p>
                                <p v-if="nextOrderEstimatedAmount" class="next-order-amount">Soit un total pour la commande du 1er {{nextMonth}}  de {{ nextOrderEstimatedAmount }}€ (hors frais de livraison éventuels)</p>
                                <p v-else class="next-order-amount">Votre première commande (1er {{nextMonth}}) sera donc de {{ amounts.subscription }}€ (hors frais de livraison éventuels)</p> -->
                            </div>
                        </div>
                        <div class="detail-content" v-if="isDraftHasProduct('subscription','product')">
                            <div class="order-category">Produits</div>
                            <div class="order-items">
                                <div
                                    class="order-item"
                                    v-for="(product, productIndex) in getProducts('subscription', 'product')"
                                    :key="productIndex"
                                >
                                    <div class="item-photo">
                                        <img class="item-image" :src="product.photo" />
                                    </div>
                                    <div class="item-informations">
                                        <div class="item-title">{{ product.title }}</div>
                                        <div class="item-description" v-html="product.description"></div>
                                      <div class="item-description options" v-html="getProductOptions(product)"></div>
                                        <div class="item-legend" v-html="product.legend"></div>
                                        <FrequencySelector
                                            class="frequency-select"
                                            :value="product.frequency"
                                            @input="(frequency) => {onFrequencyChange(frequency, product)}"
                                        />
                                    </div>
                                    <div class="item-actions">
                                        <QuantitySelector
                                            class="quantity-select"
                                            :value="product.quantity"
                                            @input="(quantity) => {onQuantityChange('subscription', quantity, product)}"
                                        />
                                        <div class="item-price">{{ product.price }}€</div>
                                    </div>
                                </div>
                                 <div class="order-item">
                                    <div class="item-informations-delivery">La première commande pour {{getProducts('subscription', 'product').length > 1 ? 'ces produits sera livrée' : 'ce produit sera livrée'}} entre le <strong>5 et le 15 {{ nextMonth }}</strong>.<br/>
                                    Vous pourrez modifier/annuler cette commande depuis le menu "Abonnement" jusqu'au <strong>{{orderCloseDate}} inclus</strong>.<br/><br/>
                                    Une fois cette première commande passée, {{getProducts('subscription', 'product').length > 1 ? 'chaque' : 'votre'}} produit sera livré entre <strong>le 5 et le 15 des mois suivants</strong>, selon la fréquence que vous avez selectionnée.<br/>
                                    Vous aurez toujours <strong>un mois complet</strong> pour modifier/annuler chaque commande, et vous pouvez annuler l'abonnement <strong>à n'importe quel moment</strong>.</div>
                                </div>
                            </div>

                        </div>
                        <div class="detail-content" v-if="isDraftHasProduct('subscription','service')">
                            <div class="order-category">Services</div>
                            <div class="order-items">
                                <div
                                    class="order-item"
                                    v-for="(service, serviceIndex) in getProducts('subscription', 'service')"
                                    :key="serviceIndex"
                                >
                                    <div class="item-photo">
                                        <img class="item-image" :src="service.photo" />
                                    </div>
                                    <div class="item-informations">
                                        <div class="item-title">{{ service.title }}</div>
                                        <div class="item-description" v-html="getServiceOptions(service)"></div>
                                        <div class="item-legend" v-html="service.legend"></div>
                                        <FrequencySelector
                                            class="frequency-select"
                                            :value="service.frequency"
                                            @input="(frequency) => {onFrequencyChange(frequency, service)}"
                                        />
                                    </div>
                                    <div class="item-actions">
                                        <QuantitySelector
                                            class="quantity-select"
                                            :addDisabled="true"
                                            :value="service.quantity"
                                            @input="(quantity) => {onQuantityChange('subscription', quantity, service)}"
                                        />
                                        <div class="item-price">{{ service.price }}€</div>
                                    </div>
                                </div>
                                 <div class="order-item">
                                    <div class="item-informations-delivery">{{ getProducts('subscription', 'service').length > 1 ? 'Les prestations choisies seront réalisées' : 'La prestation choisie sera réalisée' }} entre le <strong>2 et le {{ nextMonthLastDay }}</strong> ({{ getProducts('subscription', 'service').length>1 ? 'le(s) prestataire(s)' :'le prestataire' }} prendra directement rendez-vous avec l'EHPAD).<br/>
                                    Vous pourrez modifier/annuler cette commande depuis le menu "Abonnement" jusqu'au <strong>{{orderCloseDate}} inclus</strong>.<br/><br/>
                                    Une fois cette première commande passée, {{getProducts('subscription', 'service').length > 1 ? 'chaque' : 'votre'}} prestation sera réalisée entre le <strong>2ème et le dernier jour des mois suivants</strong>, selon la fréquence que vous avez selectionnée.<br/>
                                    Vous aurez toujours <strong>un mois complet</strong> pour modifier/annuler chaque commande, et vous pouvez annuler l'abonnement <strong>à n'importe quel moment</strong>.</div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>

            </div>
            <div class="vx-col right-col">
              <BasketSummary
                v-if="loaded"
                class="basket-summary"
                :toasted="isBasketSummaryToasted"
                :once-quantity="quantities.once"
                :once-amount="amounts.once"
                :subscription-quantity="quantities.subscription"
                :subscription-amount="amounts.subscription"
                :subscription-amount-by-frequency="subscriptionAmountByFrequency"
                :empty="isDraftEmpty()"
                :next-disabled="isDraftEmpty()"
                :nextLabel="(isDraftHasProduct('subscription') && !isDraftHasProduct('once') && getResidentPaymentData(resident_id) != null) ? 'Enregistrer': 'Passer à la livraison'"
                @next="onNextBtnClick"
              />
            </div>
            <hx-popup
                :buttonCloseHidden="true"
                :modal="true"
                classContent="saveSubscription"
                title="Abonnement enregistré" :active.sync="displayFinalPopin">
                <p>Votre abonnement ainsi que votre prochaine commande (envoyée aux vendeurs le 1er {{nextMonth}}) ont été mis à jour.</p>
                <br/>
                    <vs-button @click="onGoToNextOrderBtnClick">Voir ma prochaine commande</vs-button>
            </hx-popup>
        </div>

    </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'

import BackButton from '@/components/BackButton'
import ResidentHeader from '@/views/residents/components/ResidentHeader.vue'
import QuantitySelector from '@/components/selectors/QuantitySelector'
import FrequencySelector from '@/components/selectors/FrequencySelector'
import BasketSummary from '@/components/BasketSummary'
import {
  getProductOptionsAsHtml,
  getServiceOptionsAsHtml
} from '@/modules/services'
import { getNextMonth } from '@/modules/date'

import Routes from '@/constants/routes'
import Formats from '@/constants/formats'
import Storage from '@/storage'

export default {
    name: 'StoreBasket',
    props: [
        'resident_id'
    ],
    components: {
        QuantitySelector,
        FrequencySelector,
        BasketSummary,
        ResidentHeader,
        BackButton
    },
    data () {
        return {
            loaded: false,
            amounts: {
              once: 0,
              subscription: 0,
              all: 0
            },
            quantities: {
              once: 0,
              subscription: 0
            },
            subscriptionAmountByFrequency: null,
            displayFinalPopin: false
        }
    },
    computed: {
        institutionId () {
            return this.$store.getters['dataUser/getInstitutionId']()
        },
        orderCloseDate () {
            moment.locale('fr')
            return moment().endOf('month').format(Formats.DisplayDateText)
        },
        userId () {
            return this.$store.getters['dataUser/getUserId']()
        },
        currentPcp () {
            return this.$store.getters['dataPcps/getResidentPcp'](this.resident_id)
        },
        currentResident () {
            return this.$store.getters['dataResidents/getResidentById'](this.resident_id)
        },
        nextMonth () {
          return getNextMonth()
        },
        nextMonthLastDay () {
          moment.locale('fr')
          return moment().add(1, 'M').endOf('month').format(Formats.DisplayDateText)
        },
        isBasketSummaryToasted () {
          return this.$store.state.windowWidth < 768
        }
    },
    methods: {
        getUserId() {
            return this.$store.getters['dataUser/getUserId']()
        },
        getResidentById (residentId) {
          return this.$store.getters['dataResidents/getResidentById'](residentId)
        },
        getFirstDraft (userId, residentId, draftType) {
            return this.$store.getters['dataDrafts/getFirstDraft'](userId, residentId, draftType)
        },
        getCurrentDraft (draftType) {
            return this.getFirstDraft(this.getUserId(), this.resident_id, draftType)
        },
        getProducts (draftType, productType) {
            return this.$store.getters['dataDrafts/getProducts'](this.getUserId(), this.resident_id, draftType, productType)
        },
        getProductCount (draftType, productType) {
            return this.$store.getters['dataDrafts/getProductCount'](this.getUserId(), this.resident_id, draftType, productType)
        },
        getQuantitySum (draftType, productType) {
            return this.$store.getters['dataDrafts/getQuantitySum'](this.getUserId(), this.resident_id, draftType, productType)
        },
        getDraftAmount (draftType, productType) {
            return this.$store.getters['dataDrafts/getDraftAmount'](this.getUserId(), this.resident_id, draftType, productType)
        },
        isDraftEmpty (draftType = null) {
            return this.getProductCount(draftType) < 1
        },
        isDraftHasProduct (draftType, productType) {
            return this.getProductCount(draftType, productType) > 0
        },
        getServiceOptions (service) {
            return getServiceOptionsAsHtml(service)
        },
        getProductOptions (product) {
          return getProductOptionsAsHtml(product.meta)
        },
        getCurrentMultiBasketByResidentId () {
            return this.$store.getters['dataMultiBasket/getBasketByResidentId'](this.resident_id)
        },
        getResidentName () {
            return this.$store.getters['dataResidents/getResidentName'](this.resident_id)
        },
        getResidentPaymentData (residentId) {
            var resident = this.$store.getters['dataResidents/getResidentById'](residentId)
            if (resident && resident.stripePaymentMethod) {
                return resident.stripePaymentMethod
            }
            return null
        },
        getSubscriptionAmountByFrequency () {
            return this.$store.getters['dataDrafts/getSubscriptionAmountByFrequency'](this.getUserId(), this.resident_id)
        },
        onBackBtnClick (e) {
            this.$router.push({
                name: Routes.ResidentCatalog,
                params: {
                    resident_id: this.resident_id
                }
            })
        },
        onGoToNextOrderBtnClick (e) {
            this.$router.push({
                name: Routes.ResidentSubscriptionsNext,
                params: {
                    resident_id: this.resident_id
                }
            })
        },
        showErrorToast (text) {
            this.$toasted.show(text, {
                theme: 'hx-toasted',
                position: 'hx-bottom-left',
                duration : null,
                action : [
                    {
                        text : 'Fermer',
                        onClick : (e, to) => {
                            to.goAway(0)
                        }
                    }
                ]
            })
        },
        async onNextBtnClick (e) {
            if (this.$gtm.enabled()) {
              const draftOnceProducts = this.getProducts('once', 'product')
              const draftOnceServices = this.getProducts('once', 'service')
              const itemsToPush = []
              if (draftOnceProducts) {
                for (const draftOnceProduct of draftOnceProducts) {
                  itemsToPush.push({
                    item_id: draftOnceProduct.id,
                    item_name: draftOnceProduct.title,
                    price: draftOnceProduct.price,
                    item_category: '',
                    item_category_2: '',
                    quantity: draftOnceProduct.quantity
                  })
                }
              }
              if (draftOnceServices) {
                for (const draftOnceService of draftOnceServices) {
                  itemsToPush.push({
                    item_id: draftOnceService.id,
                    item_name: draftOnceService.title,
                    price: draftOnceService.price,
                    item_category: '',
                    item_category_2: '',
                    quantity: draftOnceService.quantity
                  })
                }
              }
              if (itemsToPush.length > 0) {
                this.$gtm.trackEvent({
                  event: 'begin_checkout',
                  ecommerce: {
                      items: itemsToPush
                  },
                  value: this.amounts.once,
                  currency: 'EUR',
                  buyMode:'once'
                })
              }
            }
            if (this.isDraftHasProduct('subscription') && !this.isDraftHasProduct('once')) {
                this.$vs.loading()
                //Cleaning server side basket if existing
                const currentBasket = await this.getCurrentMultiBasketByResidentId()
                if (currentBasket) {
                    const draft_once = {
                      institutionId : this.institutionId,
                      products : [{}]
                    }
                    const resident_id = this.resident_id
                    await this.$store.dispatch('dataMultiBasket/updateBasket', {
                        draft_once, currentBasket, resident_id, residentName: this.getResidentName()
                    })
                }
                // if MDP already recorded and abonnement only, record the modification directly
                if (this.getResidentPaymentData(this.resident_id) != null) {
                  const draftProducts = this.getProducts('subscription', 'product')
                  const draftServices = this.getProducts('subscription', 'service')
                  try {
                    await this.$store.dispatch('dataPcps/mergePcp', {
                      residentId: this.resident_id,
                      pcp: this.currentPcp,
                      products: draftProducts,
                      services: draftServices
                    })
                  }
                  catch (err) {
                      console.error(err)
                      this.showErrorToast(`Impossible de sauvegarder l'abonnement: ${err.message}`)
                  }
                  this.$store.commit('dataDrafts/DELETE_USER_DRAFTS', {
                      userId: this.getUserId()
                  })
                  this.$store.commit('dataDrafts/SAVE_DRAFTS')
                  this.$vs.loading.close()
                  this.displayFinalPopin = true
                }
                // else if MDP not recorded and abonnement only, go straight to payment screens
                else {
                  this.$vs.loading.close()
                  this.$router.push({
                      name: Routes.ResidentStorePayment,
                      params: {
                          resident_id: this.resident_id
                      }
                  })
                }
            }
            else {
              //else go to delivery
              this.$router.push({
                  name: Routes.ResidentStoreShipping,
                  params: {
                      resident_id: this.resident_id
                  }
              })
            }
        },
        onQuantityChange (draftType, quantity, product) {
            if (this.$gtm.enabled()) {
                this.$gtm.trackEvent({
                    event: (quantity < product.quantity ? 'remove_from_cart' : 'add_to_cart'),
                    currency: 'EUR',
                    value: product.price,
                    ecommerce: {
                        items: [ {
                            item_id: product.id,
                            item_name: product.title,
                            price:product.price,
                            item_category: "",
                            item_category_2: "",
                            quantity:1
                        }]
                    },
                    buyMode:this.buyMode
                })
            }
            const draft = this.getCurrentDraft(draftType)
            if (!draft) {
                return
            }
            if (quantity) {
                var productToUpdate = _.clone(product)
                productToUpdate.quantity = quantity
                this.$store.commit('dataDrafts/ADD_OR_UPDATE_PRODUCT', {
                    draftId: draft.id,
                    product: productToUpdate
                })
            }
            else {
                this.$store.commit('dataDrafts/DELETE_PRODUCT', {
                    draftId: draft.id,
                    product
                })
            }
            this.$store.commit('dataDrafts/SAVE_DRAFTS')
            this.updateSummary()
        },
        onFrequencyChange (frequency, product) {
            const draft = this.getCurrentDraft('subscription')
            if (!draft) {
                return
            }
            var productToUpdate = _.clone(product)
            productToUpdate.frequency = frequency
            product.frequency = frequency
            this.$store.commit('dataDrafts/ADD_OR_UPDATE_PRODUCT', {
                draftId: draft.id,
                product: productToUpdate
            })
            this.$store.commit('dataDrafts/SAVE_DRAFTS')
            this.updateSummary()
        },
        updateSummary() {
            this.amounts.all = this.getDraftAmount()
            this.amounts.once = this.getDraftAmount('once')
            this.amounts.subscription = this.getDraftAmount('subscription')
            this.quantities.once = this.getQuantitySum('once')
            this.quantities.subscription = this.getQuantitySum('subscription')
            this.subscriptionAmountByFrequency = this.getSubscriptionAmountByFrequency()
            this.loaded = true
        }
    },
    async mounted () {
        this.$vs.loading.close()
        const resident = await this.getResidentById(this.resident_id)
        this.loaded = false
        this.updateSummary()
        await this.$store.dispatch('dataResidents/getResidents', {
            userId: this.userId,
            institutionId: this.institutionId
        })
        if (!this.currentPcp) {
            await this.$store.dispatch('dataPcps/getResidentPcp', {
                residentId: this.resident_id
            })
        }
    }
}
</script>

<style lang="scss">
@import '~@happytal/bo-ui-library/src/styles/variables';

#resident-store-basket {
    .delivery-header {
        display: flex;
        align-items: center;
        margin: 0px 10px 22px 0px;
        font-family: Montserrat;

        @media (max-width: map-get($breakpoints, sm)) {
            margin: 0px 10px 22px 10px;
        }

        .delivery-icon {
            width: 42px;
            height: 46px;
        }
        .delivery-content {
            margin: 0px 0px 0px 18px;

            .delivery-title {
                font-weight: 600;
                color: #2C2C2C;
            }
            .delivery-legend {

            }
        }
    }

    .basket-empty {
        color: #2C2C2C;

        .title {
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 29px;
        }
        .legend {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
        }
    }

    .basket-detail {
        margin: 0px 0px 72px 0px;
        border: 2px solid #D8D8D8;
        border-radius: 8px;
        font-family: Montserrat;
        color: #2C2C2C;

        .detail-header {
            display: flex;
            align-items: center;
            padding: 22px 22px 22px 22px;

            .header-title {
                flex-grow: 1;
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;
            }
            .header-price {
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;
                text-align: right;

                @media (min-width: map-get($breakpoints, sm)) {
                    margin: 0px 0px 0px 20px;
                    min-width: 110px;
                }

                .next-order-amount {
                    font-weight: normal;
                    font-size: 12px;
                }
            }
        }
        .detail-content:last-child {
            margin: 0px 0px 0px 0px;
        }
        .detail-content {
            margin: 0px 0px 37px 0px;
            box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.135216);
            border-radius: 8px;
            background: white;

            .order-category {
                padding: 10px 22px 10px 22px;
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;
            }
            .order-items {

                .order-item {
                    display: flex;
                    padding: 22px 22px 22px 22px;
                    border-top: 1px solid #D8D8D8;

                    @media (max-width: map-get($breakpoints, sm)) {
                      padding: 22px 8px 22px 8px;
                    }

                    .item-photo {

                        .item-image {
                            width: 92px;
                            height: auto;
                        }
                    }
                    .item-informations {
                        margin: 0px 18px 0px 18px;

                        @media (min-width: map-get($breakpoints, md)) {
                          flex-grow: 1;
                        }
                        @media (max-width: map-get($breakpoints, sm)) {
                          width: 125px;
                        }

                        .item-title {
                            margin: 0px 10px 0px 0px;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 18px;
                        }
                        .item-description {
                            margin: 4px 0px 0px 0px;
                            font-style: normal;
                            font-weight: 300;
                            font-size: 14px;
                            line-height: 16px;

                          &.options {
                            margin-top: 10px;
                          }
                        }
                        .item-legend {
                            margin: 4px 0px 0px 0px;
                            font-style: normal;
                            font-weight: 300;
                            font-size: 14px;
                            line-height: 14px;
                        }
                        .frequency-select {
                            margin: 9px 0px 0px 0px;

                            @media (min-width: map-get($breakpoints, sm)) {
                              width: 202px;
                            }
                            @media (max-width: map-get($breakpoints, sm)) {
                              width: 150px;
                            }
                        }
                    }
                    .item-actions {

                        .quantity-select {
                            margin: 0px 0px 30px 0px;
                            border: 1px solid rgba(var(--vs-primary), 1);
                            border-radius: 5px;

                            @media (min-width: map-get($breakpoints, md)) {
                              width: 200px;
                            }
                            @media (max-width: map-get($breakpoints, sm)) {
                              max-width: 200px;
                            }
                        }
                        .item-price {
                            font-style: normal;
                            font-weight: 600;
                            font-size: 18px;
                            line-height: 14px;
                            text-align: right;
                        }
                    }
                    .item-informations-delivery {

                      @media (max-width: map-get($breakpoints, sm)) {
                        padding: 0px 10px 0px 10px;
                      }
                    }
                }
            }
        }
    }

    @media (max-width: map-get($breakpoints, sm)) {
      .page-row {
          flex-wrap: wrap;
      }
      .left-col {
          flex-grow: 1;
      }
      .right-col {
          flex-grow: 1;

          .basket-summary {
              position: relative;
          }
      }
    }
    @media (min-width: map-get($breakpoints, sm)) {
      .page-row {
          flex-wrap: nowrap;
      }
      .left-col {
          flex-grow: 1;
      }
      .right-col {
          flex-shrink: 0;
          width: 380px;

          .basket-summary {
              position: fixed;
              width: 350px;
          }
      }
    }
    @media (min-width: map-get($breakpoints, md)) {
      .page-row {
          flex-wrap: nowrap;
      }
      .left-col {
          flex-grow: 1;
      }
      .right-col {
          flex-shrink: 0;
          flex-basis: 480px;

          .basket-summary {
              position: fixed;
              width: 450px;
          }
      }
    }
}

.saveSubscription{
    display: flex;
    flex-direction: column;
    align-items: center;
}

</style>
