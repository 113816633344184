<template>
    <HSelect
        class="frequency-selector"
        placeholder="Fréquence"
        :value="optionValue"
        :options="frequencies"
        label="label"
        track-by="value"
        :searchable="false"
        @input="handleValueChange"
        data-cy="frequency-select"
    />
</template>

<script>
import _ from 'lodash'

import {
    getFrequencyLabel
} from '@/modules/labels'

import {
    HSelect
} from '@happytal/bo-ui-library'

export default {
    name: 'FrequencySelector',
    components: {
        HSelect
    },
    props: {
        value: {
            type: Number,
            required: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            frequencies: []
        }
    },
    computed: {
        optionValue () {
            return _.find(this.frequencies, (frequency) => {
                return this.value == frequency.value
            })
        }
    },
    methods: {
        handleValueChange (option) {
            this.$emit('input', option.value)
            this.$emit('change', option.value)
        }
    },
    created () {
        for (var i=1; i<7; i++) {
            this.frequencies.push({
                value: i,
                label: getFrequencyLabel(i)
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.frequency-selector::v-deep {
    width: 100%;

    .multiselect--active {
      z-index: 2;
    }
}
</style>
