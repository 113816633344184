import { render, staticRenderFns } from "./FrequencySelector.vue?vue&type=template&id=325161a3&scoped=true&"
import script from "./FrequencySelector.vue?vue&type=script&lang=js&"
export * from "./FrequencySelector.vue?vue&type=script&lang=js&"
import style0 from "./FrequencySelector.vue?vue&type=style&index=0&id=325161a3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "325161a3",
  null
  
)

export default component.exports