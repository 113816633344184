<template>
    <div class="quantity-selector">
        <div
            class="blank-btn"
            v-if="value == 0 || (value === 1 && min > 0)"
        ></div>
        <div
            class="delete-btn"
            :class="{ disabled }"
            v-if="min === 0 && value === 1"
            @click="onDeleteBtnClick"
            data-cy="quantity-delete-btn"
        >
            <img class="trash-icon" src="@/assets/images/icons/trash.svg" />
        </div>
        <div
            class="remove-btn"
            :class="{ disabled }"
            v-if="value > 1"
            @click="onRemoveBtnClick"
            data-cy="quantity-remove-btn"
        >
            <img class="minus-icon" src="@/assets/images/icons/minus.svg" />
        </div>
        <div class="quantity-label" :class="{ disabled }">{{ value }}</div>
        <div
            v-if='!addDisabled'
            class="add-btn"
            :class="{ disabled }"
            @click="onAddBtnClick"
            data-cy="quantity-add-btn"
        >
            <img class="plus-icon" src="@/assets/images/icons/plus.svg" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'QuantitySelector',
    props: {
        value: {
            type: Number,
            required: true
        },
        min: {
            type: Number,
            default: 0
        },
        disabled: {
            type: Boolean,
            default: false
        },
        addDisabled: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        onDeleteBtnClick (e) {
            if (!this.disabled) {
                this.$emit('input', 0)
            }
        },
        onRemoveBtnClick (e) {
            if (!this.disabled) {
                this.$emit('input', this.value - 1)
            }
        },
        onAddBtnClick (e) {
            if (!this.disabled) {
                this.$emit('input', this.value + 1)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.quantity-selector {
    display: flex;
    align-items: center;
    height: 39px;
    padding: 0px 12px 0px 12px;

    .blank-btn {
        width: 20px;
    }
    .delete-btn {
        cursor: pointer;

        .trash-icon {
            width: 20px;
            max-width: 20px;
            margin: 3px 0px 0px 0px;
        }

        &.disabled {
            opacity: .5;
            cursor: default;
        }
    }
    .remove-btn {
        cursor: pointer;

        .minus-icon {
            width: 20px;
            max-width: 20px;
            margin: 3px 0px 0px 0px;
        }

        &.disabled {
            opacity: .5;
            cursor: default;
        }
    }
    .quantity-label {
        flex-grow: 1;
        margin: 0px 10px 0px 10px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 18px;
        text-align: center;
        color: rgba(var(--vs-primary), 1);

        &.disabled {
            opacity: .5;
            cursor: default;
        }
    }
    .add-btn {
        cursor: pointer;

        .plus-icon {
            width: 20px;
            max-width: 20px;
            margin: 3px 0px 0px 0px;
        }

        &.disabled {
            opacity: .5;
            cursor: default;
        }
    }
}
</style>
